

// (더미)상태컬러
export const dummyStatusColorDataList = [
  { id:'TODO', text:'default' },
  { id:'DOING', text:'lightgreen' },
  { id:'DONE', text:'blue' },
  { id:'HOLD', text:'orange' },
];

// (더미)상태2
export const dummy2StatusColorDataList = [
  { id:'High', text:'red' },
  { id:'Normal', text:'green' },
  { id:'Light', text:'blue' },
  { id:'None', text:'default' },
]

// 스케줄 타입 // APP, BANK, CONTENT, PAGE
export const scheduleTypeColorDataList = [
  { id:'GENERAL', text:'#FFD82A' },
  { id:'CONTENT', text:'#216bd9' },
  { id:'APP', text:'#00CA2C' },
  { id:'PAGE', text:'#6CDFC3' },
  { id:'BANK', text:'#F04848' },
]

export const common_status = [
  { id:'REGISTERED', text:'green' },
  { id:'UNREGISTERED', text:'red' },
]

// 지도 상태
export const mapStatusHexColorDataList = [
  { id:'REGISTERED', text:'#388E3B' },
  { id:'UNREGISTERED', text:'#F04848' },
  { id:'DONE', text:'#FFBC00' },
]

// 콘텐츠 상태
export const contentsStatusColorDataList = [
  { id:'DRAFT', text:'red' },
  { id:'PUBLIC', text:'green' },
  { id:'CLOSED', text:'default' },
]

// 콘텐츠 상태
export const contentsStatusHexColorDataList = [
  { id:'DRAFT', text:'#F04848' },
  { id:'PUBLIC', text:'#388E3B' },
  { id:'CLOSED', text:'#CCCCCC' },
]
// 퀘스트
export const quizLimitColorDataList = [
  { id:'middle', text:'default' },
  { id:'up', text:'red' },
  { id:'down', text:'blue' },
]
// 퀴즈
export const highScoreColorDataList = [
  { id:'N', text:'default' },
  { id:'Y', text:'red' },
]
export const quiz_quizContentType = [
  { id:'OX', text:'#82B1FF' }, // skyblue
  { id:'SELECTONE', text:'#FBC02E' }, // yellow 
  { id:'AUTOCHECK', text:'#b560cb' }, // yellow 
]
// 미션
export const mission_quizContentType = [
  { id:'AUTOCHECK', text:'#82c930' },
  { id:'SELFCHECK', text:'#fd825b' },
]

// 주식
export const stock_industry = [
  { id:'금융', text:'#8F69DD' },
  { id:'부동산', text:'#B2B930' },
  { id:'산업재', text:'#F77833' },
  { id:'소재', text:'#00919B' },
  { id:'에너지', text:'#DAC200' },
  { id:'유틸리티', text:'#3D8FE3' },
  { id:'임의소비재', text:'#FF6D87' },
  { id:'정보기술', text:'#01CD80' },
  { id:'커뮤니케이션', text:'#FFA900' },
  { id:'필수소비재', text:'#7962E6' },
  { id:'헬스케어', text:'#F16EDA' },
  { id:'ETF', text:'#7D7B7B' },
];

export const stock_game_race_type = [
  { id:'READY', text:'yellow' },
  { id:'ACCEPTING', text:'lightgreen' },
  { id:'DOING', text:'blue' },
  { id:'DONE', text:'default' },
]

// 직원관리 > 회원 권한
export const member_role = [
  { id:'ADMIN', text:'red' },
  { id:'USER', text:'green' },
]

// 직원관리 > 회원 권한
export const member_login_status = [
  { id:'OK', text:'primary' },
  { id:'LOCKED', text:'red' },
  { id:'NEED_MFA', text:'orange' },
]

// 직원관리 > 회원 권한 아이콘
export const member_login_status_icon = [
  { id:'OK', text:'PRIMARY_COLOR' },
  { id:'LOCKED', text:'RED_COLOR' },
  { id:'NEED_MFA', text:'ORANGE_COLOR' },
]

export const colorDataList = {
  // ** (더미)상태컬러
  dummy_status : dummyStatusColorDataList,
  dummy_status2 : dummy2StatusColorDataList,

  schedule_type: scheduleTypeColorDataList, // 스케줄 타입

  common_status: common_status,

  map_status_hex: mapStatusHexColorDataList,
  contents_status: contentsStatusColorDataList, // 콘텐츠 상태
  contents_status_hex: contentsStatusHexColorDataList, // 콘텐츠 상태
  // 퀘스트
  quiz_limit : quizLimitColorDataList, // 문항수
  // 퀴즈
  content_highScore : highScoreColorDataList, // 배점
  quiz_quizContentType : quiz_quizContentType, // 퀴즈 타입
  mission_quizContentType : mission_quizContentType, // 미션 타입

  // 주식
  stock_industry : stock_industry, // 종목 타입
  stock_game_race_type: stock_game_race_type, // 모의투자 레이스 타입

  member_role : member_role, // 회원권한
  member_login_status : member_login_status, // 회원 로그인 상태
  member_login_status_icon : member_login_status_icon, // 회원 로그인 상태
}