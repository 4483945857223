const stockMenuData =
[
  {
    title: '주식종목 관리',
    id: 'STOCKS',
    menuIcon: '🃏',
    routerPath: 'STOCK_STOCKS_LIST',
  },
  {
    title: '모의투자 관리',
    id: 'GAME',
    menuIcon: '🏆',
    routerPath: 'STOCK_GAME_LIST',
  },
]

export { stockMenuData }