import Vue from 'vue';
import Router from 'vue-router';
import { menuData } from '@/constants/menuData';
// import menuId from '@/constants/menuId';
import routerPath from './routerPath';
import routerComponent from '@/router/routerComponent';
import LocalStorageManager from '@/storages/LocalStorageManager';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { checkRequiresRoleLevel } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';

Vue.use(Router)

const userData = LocalStorageManager.shared.getUserData();
const mainPage = routerPath.WORLD_MAP_CONTENT_FOLDER;
const routesData = [
  {
    id:'HOME',
  },
  {
    id:'LOGIN',
    requiresAuth: false,
  },
  {
    id:'LOGIN',
    name:'LoginDirect',
    requiresAuth: false,
  },
  {
    id:'MY',
  },
  // --- worldmap ---
  // 장소관리
  // {
  //   id:'WORLD_MAP_PLACE_LIST',
  //   menu:['WORLD_MAP', 'PLACE'],
  // },
  // 콘텐츠관리
  {
    id:'WORLD_MAP_CONTENT_FOLDER',
    menu:['WORLD_MAP', 'MAP_CONTENT', 'FOLDER'],
  },
  // 목록 순서
  {
    id:'WORLD_MAP_ORDER',
    menu:['WORLD_MAP', 'MAP_CONTENT', 'ORDER'],
  },
  // 태그관리
  {
    id:'WORLD_MAP_TAG',
    menu:['WORLD_MAP', 'TAG'],
  },
  // 주식관리
  {
    id:'STOCK_STOCKS_LIST',
    menu:['STOCK', 'STOCKS'],
  },
  {
    id:'STOCK_STOCKS_MODIFY',
    menu:['STOCK', 'STOCKS'],
  },
  {
    id:'STOCK_STOCKS_REGISTER',
    menu:['STOCK', 'STOCKS'],
  },
  // 모의투자 관리
  {
    id:'STOCK_GAME_LIST',
    menu:['STOCK', 'GAME'],
  },
  {
    id:'STOCK_GAME_DETAIL',
    menu:['STOCK', 'GAME'],
    title:'모의투자 상세',
  },
  {
    id:'STOCK_GAME_REGISTER',
    menu:['STOCK', 'GAME'],
    title:'모의투자 등록',
  },
  {
    id:'STOCK_GAME_MODIFY',
    menu:['STOCK', 'GAME'],
    title:'모의투자 수정',
  },
  // 앱 관리
  // 베너관리
  {
    id: 'APP_QUIZ_BANNER',
    menu: ['APP', 'QUIZ_BANNER'],
    title: '퀴즈 베너관리',
  },
  // --- admin ---
  // 직원관리
  {
    id:'ADMIN_MEMBER_LIST',
    menu:['ADMIN', 'MEMBER'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'ADMIN_MEMBER_DETAIL',
    menu:['ADMIN', 'MEMBER'],
    title:'직원 상세',
    requiresMinRole:'ADMIN',
  },
  {
    id:'ADMIN_MEMBER_REGISTER',
    menu:['ADMIN', 'MEMBER'],
    title:'직원 등록',
    requiresMinRole:'ADMIN',
  },
  {
    id:'ADMIN_MEMBER_MODIFY',
    menu:['ADMIN', 'MEMBER'],
    title:'직원 수정',
    requiresMinRole:'ADMIN',
    isModify: true
  },
  // --- guide ---
  {
    id:'GUIDE_COMPONETS_FORMS',
    menu:['GUIDE', 'COMPONETS', 'FORMS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_TAB',
    menu:['GUIDE', 'COMPONETS', 'TAB'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_SEARCH',
    menu:['GUIDE', 'COMPONETS', 'SEARCH'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_BOARD',
    menu:['GUIDE', 'COMPONETS', 'BOARD'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_TABLE',
    menu:['GUIDE', 'COMPONETS', 'TABLE'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_CATEGORY',
    menu:['GUIDE', 'COMPONETS', 'CATEGORY'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_CALENDAR',
    menu:['GUIDE', 'COMPONETS', 'CALENDAR'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_BUTTONS',
    menu:['GUIDE', 'COMPONETS', 'BUTTONS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_BADGES',
    menu:['GUIDE', 'COMPONETS', 'BADGES'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_WIDGETS',
    menu:['GUIDE', 'COMPONETS', 'WIDGETS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_COMPONETS_STEP',
    menu:['GUIDE', 'COMPONETS', 'STEP'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_ICONS',
    menu:['GUIDE', 'ICONS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_CHARTS',
    menu:['GUIDE', 'CHARTS'],
    requiresMinRole:'ADMIN',
  },
  {
    id:'GUIDE_LAYER',
    menu:['GUIDE', 'LAYER'],
    requiresMinRole:'ADMIN',
  },
];
const routerOption = {
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === to.path) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    { path: '*', redirect: mainPage },
  ]
}
setRoutesData()
const router = new Router(routerOption);
function setRoutesData(){
  routesData.forEach(itemData => {
    // console.log('------------------');
    // console.log(itemData)
    let result = {};
    const pathId = itemData.path || itemData.id;
    const name = itemData.name || itemData.id;
    const componentId = itemData.component || itemData.id;
    result.path = routerPath[pathId];
    result.name = name;
    result.component = routerComponent[componentId];
    result.meta = {};
    result.meta.isModify = itemData.isModify != undefined ? itemData.isModify : false;
    result.meta.requiresAuth = itemData.requiresAuth != undefined ? itemData.requiresAuth : true;
    result.meta.requiresMinRole = itemData.requiresMinRole;
    if(itemData.menu && itemData.menu[0]){
      const menuItem = menuData.find(menu => menu.id === itemData.menu[0]);
      result.meta.menuData = {
        id : itemData.menu[0],
        title : menuItem.title
      }
      if(itemData.menu[1]){
        const snbMenuItem = menuItem.menuList.find(snbMenu => snbMenu.id === itemData.menu[1]);
        // console.log(snbMenuItem)
        result.meta.snbMenuData = {
          id : itemData.menu[1],
          title : snbMenuItem.title
        }
        if(itemData.menu[2]){
          const lnbMenuItem = snbMenuItem.menuList.find(lnbMenu => lnbMenu.id === itemData.menu[2]);
          // console.log(lnbMenuItem)
          result.meta.lnbMenuData = {
            id : itemData.menu[2],
            title : lnbMenuItem.title
          }
        }
      }
    }
    if(itemData.title){
      result.meta.title = itemData.title;
    }
    // console.log(result)
    routerOption.routes.push(result);
  })
}

router.beforeEach((to, _from, next) => {
  const isToken = LocalStorageManager.shared.getToken();
  const userData = LocalStorageManager.shared.getUserData();
  const toRequiresAuth = to.meta.requiresAuth != false ? true : false;
  // const fromRequiresAuth = _from.meta.requiresAuth != false ? true : false;
  const requiresMinRole = to.meta.requiresMinRole;
  const requiresUser = to.meta.requiresUser;
  const authOk = isToken && userData.loginStatus === 'OK' && userData.userStatus === 'NORMAL';
  // 토큰 누락시
  if (!authOk) {
    // console.log('토큰 누락시')
    if(userData.userStatus === 'NEED_PASSWORD_CHANGE' && !store.getters['passowrdChangeError/getIsPasswordChangeError']){
      console.log('비밀번호를 변경하여주세요')
      store.dispatch('commonToast/fetchToastStart', '비밀번호를 변경하여주세요');
      store.dispatch('passowrdChangeError/fetchPasswordChangeErrorStart');
      next({ name: 'LoginDirect' });
      return;
    }
    if (to.path === routerPath.HOME) {
      store.dispatch('commonToast/fetchToastStart', '로그인 후 이용가능합니다.');
      next({ name: 'LoginDirect' });
      return;
    }
    if (toRequiresAuth) {
      store.dispatch('commonToast/fetchToastStart', '로그인 후 이용가능합니다.');
      next({ name: 'LoginDirect' });
      return;
    }
  }
  // 토큰있으면 홈 = 대시보드
  if (authOk && to.path === routerPath.HOME && _from.path != mainPage) {
    // console.log('토큰있으면 홈 = 대시보드')
    next({ path: mainPage });
    return;
  }
  // role 제어
  if(authOk && userData && requiresMinRole){
    const userRole = userData.role;
    const email = userData.email;
    const isRequiresRole = checkRequiresRoleLevel(requiresMinRole, userRole);
    const isRequiresUser = requiresUser ? requiresUser.indexOf(email) >= 0 : true;
    if(!isRequiresRole || !isRequiresUser){
      store.dispatch('commonToast/fetchToastStart', '조회 권한이 없습니다');
      next({ path: mainPage });
      return;
    }
  }
  // 동일 페이지로 이동시 막기
  if(to.path === _from.path){
    return;
  }
  next()
})

export default router
